/* 设置页面背景 */
body  {
  background-color: #0C0B1B !important;
  color: #fdfefe; /* 白色 */
}

/* index.css or App.css */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box; /* 可选：确保所有元素的尺寸计算包括边框和内边距 */
}

p {
  font-size: 16px;
  margin-top: 12px;
}


/* 设置所有按钮渐变背景 */
.custom-button {
  background: linear-gradient(to right, #4a95b7, #68d894);
  border: none;
  color: white;
  cursor: pointer;
}

/* 设置部分文字颜色 */
.gray-text {
  color: #aeb0bb; /* 灰色 */
}

/* index.css */
.footer {
  background-color: #0C0B1B; /* 设置 Footer 的背景颜色 */
  color: #aeb0bb; /* 设置文字颜色 */
  height: 100px;
  font-size: 14px !important; /* 设置字体大小 */
  text-align: center; /* 文字居中 */
  margin-bottom: 50px;
}


.ant-layout,
.ant-layout-header,
.ant-layout-sider,
.ant-layout-content,
.ant-steps-icon,
.ant-steps-item-title,
.ant-steps-item-description {
  background-color: #0C0B1B !important;
  color: #fdfefe !important;
}

.ant-steps-item-tail::after {
  background-color: rgba(255, 255, 255, 0.4) !important; /* 设置竖线颜色和透明度 */
  width: 1px !important; /* 竖线宽度 */
}
.ant-menu-item-selected {
  background-color: #4a95b7 !important;
}