.banner-container {
  background: linear-gradient(to right, #4d96b6, #68d794);
  height: 48px;
  padding: 10px 20px; /* 根据需要调整间距 */
  text-align: center;
}

.banner-content {
  display: flex;
  justify-content: center;
  align-items: center;
}


.banner-content > span {
  margin-right: 24px; /* 设置文字和按钮之间的间距 */
}

.banner-button {
  background-color: #25D795 !important;
  box-shadow: none;
}